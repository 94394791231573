@font-face {
  font-family: "Sky Text Medium";
  src: url("./fonts/SkyText_Md.ttf");
}

@font-face {
  font-family: "Sky Text Regular";
  src: url("./fonts/SkyText_Rg.ttf");
}

@font-face {
  font-family: "Sky Text Light";
  src: url("./fonts/SkyText_Lt.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* Colours */
  --llama-color-white: #f1f1f1;
  --llama-color-tile-pale: rgba(255, 255, 255, 0.08);

  /* Fonts */
  --llama-font-family-regular: "Sky Text Regular";
  --llama-font-family-medium: "Sky Text Medium";
  --llama-font-family-light: "Sky Text Light";

  --llama-font-size-small: 28px;
  --llama-font-size-medium: 32px;
  --llama-font-size-large: 40px;
  --llama-font-size-huge: 50px;
  --llama-font-size-humungous: 100px;
  --llama-font-size-ginormous: 112px;

  /* Effects */
  --llama-tile-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.12);
  --llama-text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.8);
}

iframe {
  display: none;
}
